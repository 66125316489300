import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { IAPISearchFiltersDictionary, IClasses } from '@wings-shared/core';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import { TabContext, TabPanel } from '@material-ui/lab';
import { Box, Button, Checkbox, FormControlLabel, Tab, Tabs, Typography, styled, withStyles } from '@material-ui/core';
import { sidebarMenuOperationalInfo } from '../../../Shared/Components/SidebarMenu/SidebarMenuOperationalInfo';
import { ConfirmNavigate, DetailsEditorWrapper, SidebarStore } from '@uplink-shared/layout';
import { styles } from './AirportHours.styles';
import MainTerminalOnly from './Components/MainTerminalOnly/MainTerminalOnly';
import MainTerminalBoth from './Components/MainTerminalBoth/MainTerminalBoth';
import GatBuilding from './Components/GatBuilding/GatBuilding';
import AirportHoursTab from './Components/AirportHours/AirportHoursTab';
import { VendorLocationStore } from '../../../../Stores';

interface Props {
  classes?: IClasses;
  searchFilters: IAPISearchFiltersDictionary;
  vendorLocationStore: VendorLocationStore;
}

const CustomTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    backgroundColor: 'transparent',
  },
  width: '100%',
});

const CustomTab = styled(props => <Tab disableRipple {...props} />)(() => ({
  flex: 1,
  textTransform: 'none',
  padding: 0,
  color: '#7C7C7C',
  fontWeight: 600,
  fontSize: '14px',
  backgroundColor: '#D3D3D3',
  '&.Mui-selected': {
    color: '#202020',
    borderLeft: '4px solid #004BA0',
    background: '#FFFFFF',
  },
}));

const AirportHours: FC<Props> = ({ classes, vendorLocationStore }) => {
  const navigate = useNavigate();
  const [ activeTab, setActiveTab ] = useState('1');
  const [ checkedTabs, setCheckedTabs ] = useState([ true, false, false, false ]);
  const [ isNextDisabled, setIsNextDisabled ] = useState(true);
  const saveDataFuncRef = useRef({});

  useEffect(() => {
    SidebarStore.setNavLinks(sidebarMenuOperationalInfo(), 'vendor', 'Operational Information', '/vendor/locations');
  }, []);

  const registerSaveData = (key, saveDataFunc) => {
    if (saveDataFunc) {
      saveDataFuncRef.current[key] = saveDataFunc;
    } else {
      delete saveDataFuncRef.current[key];
    }
  };

  const handleSaveClick = () => {
    const saveDataFunc = saveDataFuncRef.current[activeTab];
    if (saveDataFunc) {
      saveDataFunc();
    } else {
      console.warn('Save function not available for', activeTab);
    }
  };

  const handleNextButtonDisableState = disabled => {
    setIsNextDisabled(disabled);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
    setIsNextDisabled(true);
  };

  const handleCheckboxChange = (index: number, checked: boolean) => {
    const newCheckedTabs = [ ...checkedTabs ];
    newCheckedTabs[index] = checked;
    setCheckedTabs(newCheckedTabs);
  };

  const TABS = [
    {
      label: 'Airport Hours',
      value: '1',
      component: AirportHoursTab,
      desc: 'Airport Hours',
      onNextButtonDisable: handleNextButtonDisableState,
      registerSaveData: func => registerSaveData('1', func),
    },
    {
      label: 'Main Terminal (Only)',
      value: '2',
      component: MainTerminalOnly,
      desc: 'Main Terminal, used by both for airlines and private flights (no dedicated VIP area/terminal)',
      onNextButtonDisable: handleNextButtonDisableState,
      registerSaveData: func => registerSaveData('2', func),
    },
    {
      label: 'Main Terminal (Both)',
      value: '3',
      component: MainTerminalBoth,
      desc: 'Main Terminal, with a dedicated VIP area/terminal within it',
      onNextButtonDisable: handleNextButtonDisableState,
      registerSaveData: func => registerSaveData('3', func),
    },
    {
      label: 'GAT Building',
      value: '4',
      component: GatBuilding,
      desc: 'General Aviation Terminal (GAT) building (separate,stand alone facility)',
      onNextButtonDisable: handleNextButtonDisableState,
      registerSaveData: func => registerSaveData('4', func),
    },
  ];

  const headerActions = (): ReactNode => {
    return (
      <>
        <Typography variant="h5">Available Handler Facilities at Airport</Typography>
        <Box sx={{ display: 'flex' }}>
          <div className={`${classes.defaultButton}`}>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => navigate('/vendor/locations')}
              size="large"
            >
              Cancel
            </Button>
          </div>
          <div className={`${classes.primaryButton} ${classes.defaultButton}`}>
            <Button
              color="primary"
              variant="contained"
              size="large"
              disabled={isNextDisabled}
              onClick={handleSaveClick}
            >
              Save
            </Button>
          </div>
        </Box>
      </>
    );
  };

  return (
    <div className={classes?.mainAirportHoursWrapper}>
      <ConfirmNavigate isBlocker={vendorLocationStore.isHoursValueChange}>
        <DetailsEditorWrapper
          headerActions={headerActions()}
          isEditMode={true}
          classes={{ headerActions: classes.headerActions }}
        >
          <div className={classes?.editorWrapperContainer}>
            <TabContext value={activeTab}>
              <CustomTabs value={activeTab} onChange={handleChange} variant="fullWidth">
                {TABS.map((tab, index) => (
                  <CustomTab key={tab.value} label={tab.label} value={tab.value} />
                ))}
              </CustomTabs>
              {TABS.map((tab, index) => (
                <TabPanel key={tab.value} value={tab.value}>
                  {index !== 0 && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkedTabs[index]}
                          onChange={e => handleCheckboxChange(index, e.target.checked)}
                          color="primary"
                        />
                      }
                      className="checkbox-wrapper-header"
                      label={tab.desc}
                    />
                  )}
                  {(index === 0 || checkedTabs[index]) && tab.component && (
                    <Box>
                      <tab.component
                        onNextButtonDisable={handleNextButtonDisableState}
                        registerSaveData={func => registerSaveData(activeTab, func)}
                      />
                    </Box>
                  )}
                </TabPanel>
              ))}
            </TabContext>
          </div>
        </DetailsEditorWrapper>
      </ConfirmNavigate>
    </div>
  );
};

export default inject('vendorLocationStore')(withStyles(styles)(observer(AirportHours)));
