import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Box, FormControlLabel, Radio, RadioGroup, withStyles } from '@material-ui/core';
import { styles } from '../../../OperationalInformation/Handler/OperationalHours/OperationalHours.styles';

interface Props {
  radioValue: number;
  onRadioChange: (value: number) => void;
  radioGroupData: { id: number; value: string; label: string }[];
  isOverTime?: boolean;
}

const AccordionRadioGroups: FC<Props> = ({ radioValue, onRadioChange, radioGroupData, isOverTime = false }) => {
  useEffect(() => {}, []);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value);
    onRadioChange(value);
  };

  return (
    <div className="accordionRadioGroupsWrapper">
      <Box>
        <RadioGroup row className="radioGroupWrapper" value={radioValue} onChange={handleRadioChange}>
          {radioGroupData.map(item => (
            <FormControlLabel
              key={item.id}
              value={item.id}
              control={<Radio color={isOverTime ? 'secondary' : 'primary'} />}
              label={item.label}
            />
          ))}
        </RadioGroup>
      </Box>
    </div>
  );
};

export default withStyles(styles)(observer(AccordionRadioGroups));
