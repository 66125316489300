import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiButton-text': {
      fontSize: '18px',
      fontWeight: 400,
      color: '#1976D2',
      lineHeight: '19.07px',
      textAlign: 'center',
      textTransform: 'capitalize',
      marginRight: '12px'
    },
  },
  dialog: {
    padding: '16px 12px 16px 22px',
    textAlign: 'center',
    borderRadius: '8px',
    backgroundColor: '#F1F1F1',
  },
  inputText: {
    borderRadius: '4px',
    border: '1px solid #D3D3D3',
    padding: '11px 10px 10px 10px',
    backgroundColor: '#FFFFFF',
  },
  cancelButton: {
    textTransform: 'capitalize',
    color: '#1976D2',
    width: '100px',
    height: '40px',
    borderRadius: '4px',
    borderColor:'#63A4FF',
  },
  yesButton: {
    backgroundColor: '#1976D2',
    textTransform: 'capitalize',
    width: '100px',
    height: '40px',
    borderRadius: '4px',
    marginLeft:'10px',
    marginRight: '20px',
    '&:hover':{
      backgroundColor:'#63A4FF',
      border:'none',
      boxShadow:'none'
    }
  },
  skipButton:{
    '&:hover':{
      backgroundColor:'transparent',
      color:'#63A4FF',
      textDecoration:'underline'
    }
  }
});
