import React, { FC, useEffect, useRef, useState, ReactNode } from 'react';
import {
  GridPagination,
  IAPIGridRequest,
  IAPIPageResponse,
  IClasses,
  SearchStore,
  UIStore,
  Utilities,
  ViewPermission,
} from '@wings-shared/core';
import { BaseStore, SettingsStore, VendorManagementStore, VendorLocationStore } from '../../../../Stores';
import { useUnsubscribe } from '@wings-shared/hooks';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { SearchHeaderV2, ISearchHeaderRef } from '@uplink-shared/form-controls';
import { finalize, takeUntil } from 'rxjs/operators';
import { inject, observer } from 'mobx-react';
import { CustomLinkButton, RootDataStore, SidebarStore } from '@uplink-shared/layout';
import AddressCore from '../../../Shared/AddressCore/AddressCore';
import { VendorLocationAddressModel, VendorLocationModel } from '../../../Shared/Models';
import { forkJoin } from 'rxjs';
import { COLLECTION_NAMES } from '../../../Shared/Enums/CollectionName.enum';
import {
  SETTING_ID,
  SettingBaseModel,
  VENDOR_ADDRESS_DATA_FILTER,
  VendorManagmentModel,
  VENDOR_LEVEL_COMPARISON_FILTERS,
  VendorModuleSecurity,
} from '../../../Shared';

import { addressGridFilters } from './Fields';
import { agGridUtilities, useAgGrid, useGridState } from '@uplink-shared/custom-ag-grid';
import { IAPIResponseVendorAddress } from '../../../Shared/Interfaces/Response/API-Response-VendorAddress';
import CustomHeader from '../../../Shared/Components/CustomHeader/CustomHeader';
import { AuthStore } from '@uplink-shared/security';
import { sidebarMenus } from '../../../Shared/Components/SidebarMenu/SidebarMenu';
import CustomButton from '../../../Shared/Components/CustomButton/CustomButton';
import CustomList from '../../../Shared/Components/CustomList/CustomList';
import { useNavigate } from 'react-router';
import CustomDialog from '../../../Shared/Components/CustomDialog/CustomDialog';
import { 
  IAPIResponseVendorLocationAddress 
} from '../../../Shared/Interfaces/Response/API-Response-VendorLocationAddress';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import CustomTooltip from '../../../Shared/Components/CustomTooltip/CustomTooltip';
import { MixPanelTrackingEvents } from '@uplink/shared';
import { AnalyticsStore } from '@uplink-shared/analytics';

interface Props {
  vendorManagementStore: VendorManagementStore;
  settingsStore: SettingsStore;
  vendorLocationStore: VendorLocationStore;
  vendorId: number;
  classes: IClasses;
}

const VendorLocationAddress: FC<Props> = ({ 
  vendorManagementStore, 
  vendorLocationStore, 
  vendorId, 
  settingsStore, 
  classes }) => {
  const unsubscribe = useUnsubscribe();
  const gridState = useGridState();
  const searchHeaderRef = useRef<ISearchHeaderRef>();
  const agGrid = useAgGrid<VENDOR_ADDRESS_DATA_FILTER, VendorLocationAddressModel>(addressGridFilters, gridState);
  const [ selectedVendor, setSelectedVendor ] = useState(new VendorManagmentModel());
  const [ selectedVendorLocation, setSelectedVendorLocation ] = useState(new VendorLocationModel());
  const navigate = useNavigate();

  useEffect(() => {
    AnalyticsStore.track(MixPanelTrackingEvents.VENDOR_LOCATION_ADDRESS);
    SidebarStore.setNavLinks(sidebarMenus(), 'vendor');
    loadVendorLocationData();
    const searchData = SearchStore.searchData.get(location.pathname);
    if (searchData) {
      searchHeaderRef.current?.setupDefaultFilters(searchData);
      SearchStore.clearSearchData(location.pathname);
    }
    setTimeout(() => {
      loadInitialData();
    }, 200);
  }, [ vendorId ]);

  const rightContent = (): ReactNode => {
    return <CustomButton variant="contained" 
      startIcon={<AddRoundedIcon />} 
      to="/vendor/locations/addresses/upsert" 
      title="Add Address" />;
  };

  const getSearchValue = (): string => {
    const searchHeader = searchHeaderRef.current?.getFilters();
    if (!searchHeader) {
      return null;
    }
    return searchHeader.searchValue || '';
  };

  const setSearchData = (): void => {
    const searchHeaderFilter = searchHeaderRef.current.getFilters();
    SearchStore.searchData.set(location.pathname, {
      searchValue: searchHeaderFilter?.searchValue,
      selectInputsValues: searchHeaderFilter?.selectInputsValues,
      chipValue: searchHeaderFilter.chipValue,
      pagination: gridState.pagination,
    });
  };

  const loadVendorLocationData = () => {
    UIStore.setPageLoader(true);
    vendorLocationStore
      ?.getVendorLocationById(RootDataStore.locationData.locationId)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe((response: VendorManagmentModel) => {
        setSelectedVendorLocation(response);
      });
  };

  const deleteAddress = (item: VendorLocationAddressModel): void => {
    // const model: VendorAddressModel = agGrid._getTableItem(rowIndex);
    UIStore.setPageLoader(true);
    gridState.setIsProcessing(true);
    vendorLocationStore
      .removVendorLocationAddress(item.id)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
          gridState.setIsProcessing(false);
          agGrid.filtersApi.resetColumnFilters();
        })
      )
      .subscribe({
        next: () => {
          agGrid._removeTableItems([ item ]);
          loadInitialData();
        },
        error: error => {
          // agGrid._startEditingCell(rowIndex, 'addressType');
          BaseStore.showAlert(error.message, item.id);
        },
      });
  };

  const loadInitialData = (pageRequest?: IAPIGridRequest) => {
    UIStore.setPageLoader(true);
    gridState.setIsProcessing(true);
    const request: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      ...agGrid.filtersApi.gridSortFilters(),
      ...pageRequest,
    };
    const addressRequest: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      filterCollection: JSON.stringify([
        {
          propertyName: 'VendorLocation.VendorLocationId',
          propertyValue: RootDataStore.locationData.locationId
        },
      ]),
      ...agGrid.filtersApi.gridSortFilters(),
      ...pageRequest,
    };
    forkJoin([
      vendorLocationStore.getVMSLocationAddresses(addressRequest, COLLECTION_NAMES.VENDOR_LOCATION_ADDRESS),
      // vendorManagementStore?.getVMSAddressComparison(addressRequest),
      settingsStore?.getSettings(request, SETTING_ID.SETTING_ADDRESS_TYPE, COLLECTION_NAMES.ADDRESS_TYPE),
    ])
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
          gridState.setIsProcessing(false);
        })
      )
      .subscribe((response: [IAPIPageResponse<IAPIResponseVendorLocationAddress>, SettingBaseModel]) => {
        const results = VendorLocationAddressModel.deserializeList(response[0].results);
        // if (results.length === 0) {
        //   vendorManagementStore.hasDataLoaded = false;
        //   return;
        // }
        gridState.setGridData(filterResults(results));
      });
  };

  const filterResults = (resultList: VendorLocationAddressModel[]): VendorLocationAddressModel[] => {
    const propertyValue = getSearchValue();
    if (propertyValue) {
      const results = resultList.filter(res => {
        return (
          matchValues(res.addressType.name, propertyValue) ||
          matchValues(res.addressLine1, propertyValue) ||
          matchValues(res.addressLine2, propertyValue) ||
          matchValues(res.cityReference?.commonName, propertyValue) ||
          matchValues(res.countryReference?.name, propertyValue) ||
          matchValues(res.stateReference?.name, propertyValue) ||
          matchValues(res.zipCode, propertyValue)
        );
      });
      return results;
    } else {
      return resultList;
    }
  };

  const matchValues = (source: string, target: string): boolean => {
    return source ? source.toLowerCase().includes(target.toLowerCase()) : false;
  };

  const onEdit = item => {
    setSearchData();
    navigate(`upsert/${item?.id}`);
  };

  const colDefNew = [
    {
      headerName: 'Address Type',
      field: 'addressType',
    },
    {
      headerName: 'Address Line 1',
      field: 'addressLine1',
    },
    {
      headerName: 'Address Line2 ',
      field: 'addressLine2',
    },
    {
      headerName: 'Country Name',
      field: 'countryReference.name',
    },
    {
      headerName: 'State Name',
      field: 'stateReference.name',
    },
    {
      headerName: 'City Name',
      field: 'cityReference.commonName',
    },
    {
      headerName: 'Zip Code',
      field: 'zipCode',
    },
    {
      field: 'actionRenderer',
      headerName: '\u00A0\u00A0\u00A0\u00A0Edit\u00A0\u00A0\u00A0\u00A0\u00A0Delete',
    },
  ];

  const getConfirmation = (item: VendorLocationAddressModel): void => {
    ModalStore.open(
      <CustomDialog
        title="Remove this Address"
        message={'Are you sure you want to remove this Address?'}
        yesButton="Remove this Address"
        noButton="Cancel"
        onNoClick={() => ModalStore.close()}
        onYesClick={() => deleteAddress(item)}
      />
    );
  };


  return (
    <>
      <CustomHeader title={<CustomTooltip title={`${selectedVendorLocation.name} Addresses - ${selectedVendorLocation.headerLabel()}`}/>} />
      <SearchHeaderV2
        placeHolder="Start typing to search"
        ref={searchHeaderRef}
        selectInputs={[]}
        onResetFilterClick={() => {
          gridState.setGridData([]);
          loadInitialData({ pageNumber: 1 });
        }}
        rightContent={rightContent}
        onFilterChange={isInitEvent => {
          gridState.setGridData([]);
          loadInitialData({ pageNumber: isInitEvent ? gridState.pagination.pageNumber : 1 });
        }}
        isLoading={UIStore.pageLoading}
      />
      <CustomList
        classes={classes}
        colDef={colDefNew}
        rowData={gridState.data}
        isHeaderVisible={false}
        showEditButton={true}
        onEdit={item => onEdit(item)}
        onDelete={item => getConfirmation(item)}
        isContact={false}
        isLocation={false}
        showDeleteButton={true}
        isVendorAddress={true}
        isLoading={UIStore.pageLoading}
      />
    </>
  );
};
export default inject('vendorManagementStore', 'vendorLocationStore', 'settingsStore')(observer(VendorLocationAddress));