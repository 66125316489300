import React, { FC, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Box, FormControl, OutlinedInput, withStyles, FormHelperText } from '@material-ui/core';
import { useGridState } from '@uplink-shared/custom-ag-grid';
import CustomAccordion from '../../../../../Shared/Components/CustomAccordion/CustomAccordion';
import MainOperationalHours from '../MainTerminalOnly/MainOperationalHours/MainOperationalHours';
import AccordionRadioGroups from '../../../../../Shared/Components/RadioGroup/AccordionRadioGroups';
import { finalize, takeUntil, catchError, mergeMap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { AlertStore } from '@uvgo-shared/alert';
import { useUnsubscribe } from '@wings-shared/hooks';
import { IAPIGridRequest, IClasses, UIStore } from '@wings-shared/core';
import {
  ServiceItemPricingStore,
  SettingsStore,
  VendorLocationHoursStore,
  VendorLocationStore,
} from '../../../../../../Stores';
import { LocationHoursModel } from '../../../../../Shared/Models/LocationHours.model';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { RootDataStore } from '@uplink-shared/layout';
import { styles } from '../../AirportHours.styles';
import { ServiceItemPricingModel, SETTING_ID } from '../../../../../Shared';

interface Props {
  classes?: IClasses;
  vendorLocationStore: VendorLocationStore;
  vendorLocationHoursStore: VendorLocationHoursStore;
  serviceItemPricingStore: ServiceItemPricingStore;
  settingsStore: SettingsStore;
  onNextButtonDisable?: (boolean) => void;
  registerSaveData: (saveData: () => void) => void;
}

const GatBuilding: FC<Props> = ({
  classes,
  vendorLocationStore,
  vendorLocationHoursStore,
  serviceItemPricingStore,
  onNextButtonDisable,
  registerSaveData,
  settingsStore,
}) => {
  const gridState = useGridState();
  const [ error, setError ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState('');

  const loadSettingHoursId = () => {
    settingsStore?.getSettings(undefined, SETTING_ID.SETTING_AIRPORT_HOURS_TYPE).subscribe();
    settingsStore?.getSettings(undefined, SETTING_ID.SETTING_AIRPORT_HOURS_SUB_TYPE).subscribe();
  };

  const handleInputValue = event => {
    const value = event.target.value;
    vendorLocationStore.isHoursValueChange = true;
    const validFloat = /^([0-9]{1,5})(\.[0-9]{1,2})?$/;
    let errorMessage = '';

    if (!value) {
      errorMessage = 'This value is required';
    } else if (!/^\d*(\.\d*)?$/.test(value)) {
      errorMessage = 'The value must be a number';
    } else if (parseFloat(value) < 0 || parseFloat(value) > 99999.99) {
      errorMessage = 'The value must be between 0 and 99999.99';
    } else if (!validFloat.test(value)) {
      errorMessage = 'This value format is invalid';
    }

    vendorLocationStore.priceValue = value;

    if (errorMessage) {
      setErrorMessage(errorMessage);
      setError(true);
    } else {
      setError(false);
    }
  };

  const loadPricingData = () => {
    UIStore.setPageLoader(true);
    const request: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      filterCollection: JSON.stringify([
        {
          propertyName: 'ServiceItem.Id',
          propertyValue: '745',
        },
        {
          propertyName: 'VendorLocation.VendorLocationId',
          propertyValue: [ RootDataStore.locationData.locationId ],
          filterType: 'in',
          operator: 'and',
        },
      ]),
    };
    serviceItemPricingStore.getVMSComparison(request).subscribe(response => {
      UIStore.setPageLoader(false);
      if (response.results.length > 0) {
        const result = ServiceItemPricingModel.deserialize(response.results[0]);
        serviceItemPricingStore.pricingId = result.id;
        vendorLocationStore.priceValue = result.price;
        vendorLocationStore.feeValue = result.uom.id;
        vendorLocationStore.isCostYes = 3;
      }
    });
  };

  const pricingErrorHandler = (errors: object): void => {
    Object.values(errors)?.forEach(errorMessage => AlertStore.info(errorMessage[0]));
  };

  const saveData = () => {
    if (vendorLocationStore.isCostYes === 4) {
      if (serviceItemPricingStore.pricingId) {
        deletePricing();
      } else if (vendorLocationStore.priceValue && vendorLocationStore.feeValue) {
        upsertPricing();
      }
    }
  };

  const upsertPricing = () => {
    UIStore.setPageLoader(true);
    const model = new ServiceItemPricingModel();
    serviceItemPricingStore
      ?.upsertServiceItemPricingLocations([
        model.serializeHoursPricing(
          serviceItemPricingStore.pricingId,
          745,
          vendorLocationStore.priceValue,
          vendorLocationStore.feeValue
        ),
      ])
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe({
        next: (response: ServiceItemPricingModel) => {
          loadPricingData();
          vendorLocationStore.isHoursValueChange = false;
        },
        error: error => {
          if (error.response.data.errors) {
            pricingErrorHandler(error.response.data.errors);
            return;
          }
          AlertStore.info(error.message);
        },
      });
  };

  const deletePricing = () => {
    UIStore.setPageLoader(true);
    gridState.setIsProcessing(true);
    serviceItemPricingStore
      .removePricing([ serviceItemPricingStore.pricingId ])
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
          gridState.setIsProcessing(false);
        })
      )
      .subscribe({
        next: () => {
          serviceItemPricingStore.pricingId = 0;
          vendorLocationStore.isHoursValueChange = false;
          vendorLocationStore.priceValue = null;
          vendorLocationStore.feeValue = 0;
          setError(false);
          loadPricingData();
        },
        error: error => {
          if (error.response.data.errors) {
            pricingErrorHandler(error.response.data.errors);
            return;
          }
          AlertStore.info(error.message);
        },
      });
  };

  const unsubscribe = useUnsubscribe();

  useEffect(() => {
    vendorLocationStore.isCostYes = 4;
    serviceItemPricingStore.pricingId = 0;
    vendorLocationStore.isHoursValueChange = false;
    vendorLocationStore.priceValue = null;
    vendorLocationStore.feeValue = 0;
    setError(false);
    loadInitialData();
    loadPricingData();
    loadSettingHoursId();
  }, []);

  const validateForm = () => {
    if (vendorLocationStore.isHoursValueChange && vendorLocationStore.isCostYes === 4) {
      if (!serviceItemPricingStore.pricingId) {
        return true;
      }
      return false;
    }

    if (vendorLocationStore.isHoursValueChange && vendorLocationStore.isCostYes === 3) {
      return vendorLocationStore.priceValue === null || vendorLocationStore.feeValue === 0 || error;
    }
    return true;
  };

  useEffect(() => {
    onNextButtonDisable(validateForm() || UIStore.pageLoading);
  }, [
    vendorLocationStore.priceValue,
    vendorLocationStore.feeValue,
    vendorLocationStore.isHoursValueChange,
    vendorLocationStore.isCostYes,
  ]);

  useEffect(() => {
    registerSaveData(saveData);
    return () => registerSaveData(null);
  }, []);

  const loadInitialData = () => {
    vendorLocationStore.isTimeChanged = false;
    UIStore.setPageLoader(true);
    const request: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      filterCollection: JSON.stringify([
        {
          propertyName: 'AirportReference.Id',
          propertyValue: RootDataStore.locationData.airportReferenceId,
        },
        {
          propertyName: 'AirportHoursType.Name',
          propertyValue: 'Operational',
          operator: 'and',
        },
        {
          propertyName: 'AirportHoursScheduleType.Name',
          propertyValue: 'Recurrence',
          operator: 'and',
        },
        {
          propertyName: 'AirportHoursSubType.Name',
          propertyValue: 'GA Terminal',
          operator: 'and',
        },
      ]),
    };
    vendorLocationHoursStore.getAirportHours(request).subscribe(() => {
      UIStore.setPageLoader(false);
      ModalStore.close();
    });
  };

  const isVIPAreaData = [
    { id: 3, value: 'yes', label: 'Yes' },
    { id: 4, value: 'no', label: 'No' },
  ];

  const gatFeeApplicationData = [
    { id: 55, value: 'Per Person', label: 'Per person, per use (arrival or departure)' },
    { id: 30, value: 'Per Use', label: 'Per facility use (regardless of pax count)' },
  ];

  const errorHandler = (errors: { [key: string]: string[] }): void => {
    Object.keys(errors).forEach(key => {
      const errorMessages = errors[key];
      errorMessages.forEach(message => {
        AlertStore.info(message);
      });
    });
  };

  const handleErrorResponse = error => {
    if (error.response?.data?.errors) {
      errorHandler(error.response?.data?.errors);
      return;
    }
    if (error?.message) {
      AlertStore.info(error?.message);
    }
  };

  const deleteAllRecords = () => {
    const ids =
      vendorLocationHoursStore.timeDataHoursData && vendorLocationHoursStore.timeDataHoursData.map(item => item.id);
    if (ids.length === 0) {
      ModalStore.close();
      return;
    }
    UIStore.setPageLoader(true);
    vendorLocationHoursStore
      ?.deleteAirportHours(ids)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: LocationHoursModel[]) => {
          AlertStore.info('Airport Hours saved successfully!');
          loadInitialData();
          ModalStore.close();
        },
        error: error => {
          handleErrorResponse(error);
        },
      });
  };

  const handleSave = () => {
    const hoursIdZeroData = vendorLocationHoursStore.updatedHoursData.filter(item => item.id === 0);
    const hoursIdOtherData = vendorLocationHoursStore.updatedHoursData.filter(item => item.id !== 0);

    if (hoursIdZeroData.length === 0 && hoursIdOtherData.length === 0) {
      deleteAllRecords();
      return;
    }

    if (hoursIdOtherData.length === 0) {
      deleteAllRecords();
    }
    const hoursTypeId = settingsStore.airportHoursType.filter(item => {
      return item.name === 'Operational';
    });

    const hoursSubTypeId = settingsStore.airportHoursSubType.filter(item => {
      return item.name === 'GA Terminal';
    });

    const apiCalls = [];

    UIStore.setPageLoader(true);
    apiCalls.push(
      vendorLocationHoursStore
        ?.updateAirportHours(
          LocationHoursModel.airportHoursSerializeList(
            hoursIdOtherData,
            hoursTypeId[0].id || 1,
            hoursSubTypeId[0].id || 21
          )
        )
        .pipe(
          mergeMap(() => {
            return forkJoin(
              vendorLocationHoursStore?.addAirportHours(
                LocationHoursModel.airportHoursSerializeList(
                  hoursIdZeroData,
                  hoursTypeId[0].id || 1,
                  hoursSubTypeId[0].id || 21
                )
              )
            );
          })
        )
        .pipe(
          catchError(error => {
            handleErrorResponse(error);
            return of(null);
          })
        )
        .pipe(
          takeUntil(unsubscribe.destroy$),
          finalize(() => UIStore.setPageLoader(false))
        )
        .subscribe(responses => {
          const allSuccessful = responses && responses.every(response => response !== null);

          if (allSuccessful) {
            AlertStore.info('Airport Hours saved successfully!');
            loadInitialData();
            ModalStore.close();
          }
        })
    );
  };

  const onRadioChange = (value: number) => {
    vendorLocationStore.isHoursValueChange = true;
    vendorLocationStore.isCostYes = value;
    if (value === 4) {
      vendorLocationStore.priceValue = null;
      vendorLocationStore.feeValue = 0;
      setError(false);
    } else {
      loadPricingData();
    }
  };

  const onFeeApplicationValueChange = (value: number) => {
    vendorLocationStore.feeValue = value;
    vendorLocationStore.isHoursValueChange = true;
  };

  return (
    <div className={classes.mainTerminalOnlyWrapper}>
      <Box>
        <CustomAccordion
          panelName="gatOperationalHours"
          panelHeading="General Aviation Terminal (GAT) - Operational Hours (in local time)"
          panelContent={
            <MainOperationalHours
              handleSave={handleSave}
              locationHoursList={vendorLocationHoursStore.airportHoursList}
            />
          }
        />
      </Box>
      <Box>
        <CustomAccordion
          panelName="isGatCostApplicable"
          panelHeading="General Aviation Terminal (GAT)"
          panelBodyHeading="Is there a fee for using the General Aviation Terminal?"
          panelContent={
            <AccordionRadioGroups
              radioValue={vendorLocationStore.isCostYes}
              onRadioChange={onRadioChange}
              radioGroupData={isVIPAreaData}
            />
          }
        />
      </Box>
      {vendorLocationStore.isCostYes === 3 && (
        <Box>
          <Box>
            <CustomAccordion
              panelName="gatCost"
              panelHeading="GAT Cost"
              panelBodyHeading="What is the cost for using the General Aviation Terminal? (In your local currency)"
              panelContent={
                <FormControl variant="outlined">
                  <OutlinedInput
                    className="inputTextField"
                    id="outlined-adornment-gat-cost"
                    value={vendorLocationStore.priceValue}
                    onChange={handleInputValue}
                    aria-describedby="outlined-terminal-cost-text"
                    inputProps={{
                      'aria-label': 'gat cost',
                    }}
                    labelWidth={0}
                    style={{
                      borderColor: error ? 'red' : 'initial',
                      borderWidth: '1px',
                      borderStyle: error ? 'solid' : 'none',
                    }}
                  />
                  {error && <FormHelperText style={{ color: 'red' }}>{errorMessage}</FormHelperText>}
                </FormControl>
              }
            />
          </Box>
          <Box className={classes.innerAccordian}>
            <CustomAccordion
              panelName="gatFeeApplication"
              panelHeading="GAT Fee application"
              panelBodyHeading="Is this fee person, or per facility use?"
              panelContent={
                <AccordionRadioGroups
                  radioValue={vendorLocationStore.feeValue}
                  onRadioChange={onFeeApplicationValueChange}
                  radioGroupData={gatFeeApplicationData}
                />
              }
            />
          </Box>
        </Box>
      )}
    </div>
  );
};

export default inject(
  'vendorLocationStore',
  'serviceItemPricingStore',
  'vendorLocationHoursStore',
  'settingsStore'
)(withStyles(styles)(observer(GatBuilding)));
