import {
  MODULE_NAMES,
  ModuleSecurityBase,
  ModulePermissions,
  MODULE_ACTIONS,
  USER_GROUP,
  AuthStore,
} from '@uplink-shared/security';

const permissions: ModulePermissions = {
  [MODULE_ACTIONS.EDIT]: [ USER_GROUP.CORPORATE_AUTHORIZED_AGENT ],
};
class VendorModuleSecurity extends ModuleSecurityBase<string> {
  constructor() {
    super(MODULE_NAMES.VENDOR);
  }

  public init(): void {
    this.setModulePermissions(permissions);
  }
}

const instance = new VendorModuleSecurity();

export default instance;
export { VendorModuleSecurity };
