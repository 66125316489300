import React from 'react';
import { INavigationLink, RootDataStore } from '@uplink-shared/layout';
import {
  HandlerIcon,
  ClockIcon,
  AircraftDocIcon,
  PassengerIcon,
  NoteIcon,
  AirplaneIcon
} from '@uvgo-shared/icons';

export const sidebarMenuOperationalInfo = (): INavigationLink[] => [
  {
    to: '/vendor/location/operational-information/operational-hours',
    title: 'Handler',
    expandedMenuBlock: 'panel2',
    icon: <HandlerIcon />,
    subMenuItem: [
      {
        to: '/vendor/location/operational-information/operational-hours',
        title: 'Operational Hours',
        isDisabled: !RootDataStore.locationData.locationId,
      },
      {
        to: '/vendor/location/operational-information/aircraft-logistics',
        title: 'Aircraft Logistics',
        isDisabled: !RootDataStore.locationData.locationId,
      },
      {
        to: '/vendor/location/operational-information/passenger-logistics',
        title: 'Passenger Logistics',
        isDisabled: !RootDataStore.locationData.locationId,
      },
      {
        to: '/vendor/location/operational-information/handler-bulletins',
        title: 'Notes',
        isDisabled: !RootDataStore.locationData.locationId,
      },
      {
        to: '/vendor/location/operational-information/ground-service-equipment',
        title: 'Ground Service Equipment',
        isDisabled: !RootDataStore.locationData.locationId,
      },
    ],
  },
  {
    to: '/vendor/location/operational-information/airport-hours',
    title: 'Airport',
    expandedMenuBlock: 'panel3',
    icon: <AirplaneIcon />,
    isDisabled: !RootDataStore.locationData.airportReferenceId,
    subMenuItem: [
      {
        to: '/vendor/location/operational-information/airport-hours',
        title: 'Airport Hours',
        isDisabled: !RootDataStore.locationData.locationId || !RootDataStore.locationData.airportReferenceId,
      }
    ],
  },
];
