export enum VENDOR_PRICING_COMPARISON_FILTERS {
    VENDOR_NAME = 'Vendor Name',
    VENDOR_CODE = 'Vendor Code',
    VENDOR_LOCATION_NAME = 'Vendor Location Name',
    AIRPORT_CODE = 'Airport Code',
    SERVICE_NAME = 'Service Name',
    PARAMETER = 'Parameter',
    CURRENCY = 'Currency',
    UNITS = 'Units',
    PRICING_STATUS = 'Status'
}

export enum VENDOR_PRICING_DATA_FILTERS {
    VENDOR_Name = 'Vendor Name',
    VENDOR_CODE = 'Vendor Code',
    VENDOR_LOCATION_Name = 'Vendor Location Name',
    AIRPORT_CODE = 'Airport Code',
    SERVICE_NAME = 'Service Name',
    PRICE_DATA = 'Price Data',
    PARAMETER = 'Parameter',
    CURRENCY = 'Currency',
    UNITS = 'Units',
    PRICING_STATUS = 'Status'
}

export enum ServiceItemPricing {
    PreferredGroundHandler = 3,
    CertifiedMember = 4
}
  