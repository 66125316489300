import { Theme, createStyles, makeStyles } from '@material-ui/core';

export const styles = (theme: Theme) =>
  createStyles({
    headerActions: {
      justifyContent: 'space-between',
      borderBottom: '1px solid #D3D3D3',
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(2),
      paddingBottom: theme.spacing(3),
      '& button': {
        width: '140px',
      },
      '& .MuiIconButton-root': {
        width: '50px !important',
      },
      '& h5': {
        fontSize: '1.625rem',
      },
      '& .MuiTypography-h6': {
        width: '300px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        wordWrap: 'normal',
        whiteSpace: 'nowrap',
        fontSize: '1.625rem',
      },
    },
    headerFlexStyle: {
      display: 'flex',
      alignItems: 'center',
    },
    defaultButton: {
      marginLeft: '10px',
      '& button': {
        border: '1px solid #1976D2',
        padding: '4px 10px',
        height: '40px',
        borderRadius: '4px',
        fontSize: '14px',
        fontWeight: '400',
        minWidth: '140px',
        textTransform: 'capitalize',
        color: '#1976D2',
      },      
    },
    primaryButton: {
      '& button': {
        background: '#1976D2',
        color: '#fff',
      },
      '& button:disabled': {
        background: '#afafaf',
        border: 'none',
      },
      '&:hover.saveButton button:disabled':{
        background: '#afafaf',
      },
      '&:hover.saveButton button':{
        backgroundColor:'rgba(99, 164, 255, 10)',
        border:'none',
        boxShadow:'none'
      },
    },
    primaryButtonWithIcon: {
      '& button': {
        color: '#1976D2',
        background: 'none',
        '& .MuiButton-startIcon': {
          backgroundColor: '#1976D2',
          borderRadius: '2px',
          padding: '2px 4px',
          fontWeight: '400',
        },
      },
    },
    editorWrapperContainer: {
      '& .disableRow': {
        color: 'gray !important',
        opacity: 0.9,
        pointerEvents: 'none',
      },
      '& .ag-cell': {
        overflow: 'hidden !important',
      },
      '& .MuiCheckbox-root': {
        width: '20px !important',
        height: '20px !important',
        padding: '4px',
        borderRadius: '3px !important',
      },
      '& .MuiTablePagination-toolbar': {
        justifyContent: 'end',
      },
    },
    customHeight: {
      height: 'calc(100vh - 240px)',
    },
    pageWrapper:{
      height: '100%',
      overflowY: 'hidden'
    },

  });
